import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import type {
  DVCJSON,
  DVCVariableValue,
} from '@devcycle/js-client-sdk';
// eslint-disable-next-line no-restricted-imports
import {
  withDevCycleProvider,
  useDevCycleClient,
  useIsDevCycleInitialized,
  useVariableValue,
} from '@devcycle/react-client-sdk';

const Features = {
  purgeErrorOnReloadEnabled:
    process.env.NEXT_PUBLIC_FEATURE_PURGE_ON_ERROR_RELOAD_ENABLED === 'true',
  canaryTextEnabled:
    process.env.NEXT_PUBLIC_FEATURE_CANARY_TEXT_ENABLED === 'true',
  // SSR
  ssrRtkQueryHydrationEnabled:
    process.env.NEXT_FEATURE_SSR_HYDRATE_RTK === 'true',
  // Debugging
  performanceMarksEnabled:
    process.env.NEXT_PUBLIC_FEATURE_PERFORMANCE_MARKS_ENABLED === 'true',
};

enum FeatureVariableKeys {
  AttendanceTileBlackoutEndDate = 'attendance-tile-blackout-end-date',
  AttendanceTileEnabledCourses = 'attendance-tile-enabled-courses',
  CbeEngagementTileBlackoutEndDate = 'cbe-engagement-tile-blackout-end-date',
  DaEngagementTileBlackoutEndDate = 'da-engagement-tile-blackout-end-date',
  excessFunds = 'excess-funds',
  excessFundsChangesTracker = 'excess-funds-changes-tracker',
  HomePageInterrupter = 'home-page-interrupter',
  loaMyphoenixRollout = 'loa-myphoenix-rollout',
  loaUnforeseenPhase = 'loa-unforeseen-circumstance-phase-2',
  loaEditPhase = 'loa-edit-phase-2',
  loaAdaCalendar = 'ada-calendar-phase-1',
  LogonEventBypassBlacklist = 'logon-event-bypass-blacklist',
  MessageCenterEnrollmentTasks = 'message-center-enrollment-tasks',
  MfeCareersStepCard = 'mfe-careers-step-card',
  MfeHeaderComponent = 'mfe-header-component',
  ProspectConversionsRegisteredAndNotFirstCourseCompleted = 'pc-registered-not-1st-course-completed',
  PreFetchValidation = 'pre-fetch-validation',
}

const FeatureVariableValues = {
  MfeHeaderComponent: {
    MfeHeader: 'mfe',
    LegacyHeader: 'legacy',
    PublicHeader: 'public',
  },
};

const useFeatureClient = () => useDevCycleClient();

function FeatureProviderPersonalizer({ personId }: { personId?: string }): null {
  const dvcClient = useDevCycleClient();
  const [dvcPersonId, setDvcPersonId] = useState<string>(null);

  useEffect(() => {
    if (personId && personId !== dvcPersonId) {
      setDvcPersonId(personId);
      dvcClient.identifyUser({
        user_id: personId,
        customData: {
          personId,
        },
      });
    }
  }, [personId, dvcPersonId, dvcClient]);

  return null;
}

function withFeatureProvider<T extends object>(
  WrappedComponent: React.ComponentType<T>,
  sdkKey: string = process.env.NEXT_PUBLIC_DEVCYCLE_CLIENT_KEY,
) {
  return withDevCycleProvider<T>({
    sdkKey,
    options: {
      deferInitialization: true,
    },
  })(WrappedComponent);
}

const useIsFeatureProviderInitialized = () => useIsDevCycleInitialized();

const useFeatureVariableValue = <T extends DVCVariableValue>(
  key: FeatureVariableKeys,
  defaultValue: T,
  useCache: boolean = true,
) => {
  const isInitialized = useIsDevCycleInitialized();
  const dvcVariableValue = useVariableValue<T>(key, defaultValue);
  return (!isInitialized && !useCache) ? defaultValue : dvcVariableValue;
};

export type FeatureVariableValueJSONArray = string[] | boolean[] | number[] | DVCJSON[];

export type FeatureVariableValueJSON = DVCJSON;

export type FeatureVariableValue = string | boolean | number | FeatureVariableValueJSON;

export {
  Features,
  FeatureVariableKeys,
  FeatureVariableValues,
  withFeatureProvider,
  useIsFeatureProviderInitialized,
  FeatureProviderPersonalizer,
  useFeatureClient,
  useFeatureVariableValue,
};
