import React from 'react';
import dynamic from 'next/dynamic';
import EmptyIcon from 'public/icons/empty.svg';

export enum IconType {
  'icon-go-to-class-easel-mc',
  'icon-successful-submission',
  'icon-upload-file',
  'icon-question-mark',
  'doc-icon-small',
  'icon-trash',
  'icon-green-check',
  'icon-red-x',
  'jpg-icon-small',
  'pdf-icon-small',
  'png-icon-small',
  'icon-microsoft',
  'icon-blackboard',
  'icon-right-arrow',
  'icon-library-mc',
  'icon-phoenix-link-mc',
  'icon-help',
  'icon-logout',
  'icon-toggle-open',
  'icon-toggle-close',
  'icon-email',
  'icon-phoenix-logo',
  'icon-copy',
  'icon-checkmark',
  'icon-long-arrow',
  'icon-email-mc',
  'icon-chat-mc',
  'icon-support-mc',
  'icon-text-sms-mc',
  'icon-generic-head',
  'icon-calendar-with-x',
  'icon-calendar-success',
  'icon-note-book',
  'icon-open-book',
  'icon-sad',
  'icon-instructor-mc',
  'icon-warning-sign',
  'icon-phoenix-head',
  'icon-print',
  'icon-info',
  'icon-info-solid',
  'icon-info-gray',
  'icon-info-blue',
  'icon-info-outlined',
  'new-notification-dot',
  'icon-info-red',
  'icon-phoebe-avatar',
  'icon-back-arrow',
  'icon-public-logo',
  'icon-spy-glass',
  'icon-failed',
  'icon-plus-mc',
  'icon-hero-calendar',
  'icon-half-green-bubble',
  'icon-empty-circle',
  'icon-minus-mc',
  'icon-triangle',
  'icon-alert-mc',
  'icon-letter-closed',
  'icon-letter-open',
  'icon-download',
  'icon-linkedin',
  'icon-close',
  'icon-refresh',
  'icon-emotion-agree-strongly',
  'icon-emotion-agree',
  'icon-emotion-neutral',
  'icon-emotion-disagree',
  'icon-emotion-disagree-strongly',
  'icon-share',
  'icon-small-line-separator',
  'icon-congratulations-mc',
  'icon-circle',
  'icon-circle-check',
  'icon-grad-cap-mc',
  'icon-phoenix-link-careers',
  'icon-virtual-student-union',
  'icon-money',
  'icon-apple-book',
  'icon-bell-badge-mc',
  'icon-document-edit-mc',
  'icon-oh-dear-mc',
  'icon-warning-mc',
  'icon-class-mc',
  'icon-contact',
  'icon-calendar-mc',
  'icon-vsu-advice',
  'icon-vsu-balance',
  'icon-vsu-connect',
  'icon-vsu-findadoc',
  'icon-vsu-problem',
  'icon-vsu-study',
  'icon-pause-mc',
  'icon-missed-mc',
  'icon-caret',
  'icon-thumbs-up-mc',
  'icon-star',
  'icon-star-1',
  'icon-voucher-invalid',
  'icon-voucher-duplicate',
  'icon-misc-trophy',
  'icon-misc-newtab',
  'icon-bulleted-lines',
  'icon-clock',
  'icon-pdf-small', // remove after voucher upload component is merged , this will conflict
  'icon-check-circle-mc',
  'icon-alert-sharp',
  'icon-green-circle-check',
  'icon-orange-alert-mc',
  'icon-diploma-ribbon',
  'icon-documents-stack',
  'icon-bio-science',
  'icon-social-science',
  'icon-elective',
  'icon-science-tech',
  'icon-book-humanities',
  'icon-apple-clipboard',
  'icon-drawing-board',
  'icon-black-red-check',
  'icon-add-red',
  'icon-info-red-large',
  'icon-celebration',
  'icon-padlock',
  'icon-career-navigator',
  'empty',
}

export type AppIcon = keyof typeof IconType;

export const IconNameList = Object.values(IconType).filter((item) => typeof item === 'string');

// TODO: Enable this but also work with JEST
// const EmptyIconElement = EmptyIcon();
// Cannot refactor options to a const https://nextjs.org/docs/messages/invalid-dynamic-options-type
// Have loading run an empty arrow to load the empty icon element without additional props
const icons: { [key in IconType]: React.FunctionComponent | React.ComponentType } = {
  [IconType['icon-question-mark']]: dynamic(() => import('public/icons/icon-question-mark.svg'), { loading: () => null }),
  [IconType['icon-successful-submission']]: dynamic(() => import('public/icons/icon-successful-submission.svg'), { loading: () => null }),
  [IconType['icon-upload-file']]: dynamic(() => import('public/icons/icon-upload-file.svg'), { loading: () => null }),
  [IconType['doc-icon-small']]: dynamic(() => import('public/icons/doc-icon-small.svg'), { loading: () => null }),
  [IconType['icon-trash']]: dynamic(() => import('public/icons/icon-trash.svg'), { loading: () => null }),
  [IconType['icon-green-check']]: dynamic(() => import('public/icons/icon-green-check.svg'), { loading: () => null }),
  [IconType['icon-red-x']]: dynamic(() => import('public/icons/icon-red-x.svg'), { loading: () => null }),
  [IconType['jpg-icon-small']]: dynamic(() => import('public/icons/jpg-icon-small.svg'), { loading: () => null }),
  [IconType['pdf-icon-small']]: dynamic(() => import('public/icons/pdf-icon-small.svg'), { loading: () => null }),
  [IconType['png-icon-small']]: dynamic(() => import('public/icons/png-icon-small.svg'), { loading: () => null }),
  [IconType['icon-go-to-class-easel-mc']]: dynamic(() => import('public/icons/go-to-class-easel-mc.svg'), { loading: () => null }),
  [IconType['icon-microsoft']]: dynamic(() => import('public/icons/office-365-mc.svg'), { loading: () => null }),
  [IconType['icon-blackboard']]: dynamic(() => import('public/icons/blackboard.svg'), { loading: () => null }),
  [IconType['icon-hero-calendar']]: dynamic(() => import('public/icons/icon-hero-calendar.svg'), { loading: () => null }),
  [IconType['icon-half-green-bubble']]: dynamic(() => import('public/icons/icon-half-green-bubble.svg'), { loading: () => null }),
  [IconType['icon-empty-circle']]: dynamic(() => import('public/icons/icon-empty-circle.svg'), { loading: () => null }),
  [IconType['icon-right-arrow']]: dynamic(() => import('public/icons/right-arrow.svg'), { loading: () => null }),
  [IconType['icon-library-mc']]: dynamic(() => import('public/icons/icon-library-mc.svg'), { loading: () => null }),
  [IconType['icon-phoenix-link-mc']]: dynamic(() => import('public/icons/icon-phoenix-link-mc.svg'), { loading: () => null }),
  [IconType['icon-help']]: dynamic(() => import('public/icons/icon-help.svg'), { loading: () => null }),
  [IconType['icon-logout']]: dynamic(() => import('public/icons/icon-logout.svg'), { loading: () => null }),
  [IconType['icon-toggle-open']]: dynamic(() => import('public/icons/icon-toggle-open.svg'), { loading: () => null }),
  [IconType['new-notification-dot']]: dynamic(() => import('public/icons/new-notification-dot.svg'), { loading: () => null }),
  [IconType['icon-misc-trophy']]: dynamic(() => import('public/icons/icon-misc-trophy.svg'), { loading: () => null }),
  [IconType['icon-toggle-close']]: dynamic(() => import('public/icons/icon-toggle-close.svg'), { loading: () => null }),
  [IconType['icon-email']]: dynamic(() => import('public/icons/icon-email.svg'), { loading: () => null }),
  [IconType['icon-phoenix-head']]: dynamic(() => import('public/icons/phoenix-head.svg'), { loading: () => null }),
  [IconType['icon-phoenix-logo']]: dynamic(() => import('public/icons/icon-phoenix-logo.svg'), { loading: () => null }),
  [IconType['icon-print']]: dynamic(() => import('public/icons/print.svg'), { loading: () => null }),
  [IconType['icon-copy']]: dynamic(() => import('public/icons/copy.svg'), { loading: () => null }),
  [IconType['icon-long-arrow']]: dynamic(() => import('public/icons/icon-long-arrow.svg'), { loading: () => null }),
  [IconType['icon-email-mc']]: dynamic(() => import('public/icons/email-mc.svg'), { loading: () => null }),
  [IconType['icon-chat-mc']]: dynamic(() => import('public/icons/chat-mc.svg'), { loading: () => null }),
  [IconType['icon-support-mc']]: dynamic(() => import('public/icons/support-mc.svg'), { loading: () => null }),
  [IconType['icon-text-sms-mc']]: dynamic(() => import('public/icons/text-sms-mc.svg'), { loading: () => null }),
  [IconType['icon-generic-head']]: dynamic(() => import('public/icons/icon-generic-head.svg'), { loading: () => null }),
  [IconType['icon-calendar-with-x']]: dynamic(() => import('public/icons/icon-calendar-with-x.svg'), { loading: () => null }),
  [IconType['icon-calendar-success']]: dynamic(() => import('public/icons/icon-calendar-success.svg'), { loading: () => null }),
  [IconType['icon-note-book']]: dynamic(() => import('public/icons/icon-note-book.svg'), { loading: () => null }),
  [IconType['icon-open-book']]: dynamic(() => import('public/icons/icon-open-book.svg'), { loading: () => null }),
  [IconType['icon-sad']]: dynamic(() => import('public/icons/icon-sad.svg'), { loading: () => null }),
  [IconType['icon-instructor-mc']]: dynamic(() => import('public/icons/icon-instructor-mc.svg'), { loading: () => null }),
  [IconType['icon-checkmark']]: dynamic(() => import('public/icons/icon-check-mark.svg'), { loading: () => null }),
  [IconType['icon-info-gray']]: dynamic(() => import('public/icons/icon-info-gray.svg'), { loading: () => null }),
  [IconType['icon-info-blue']]: dynamic(() => import('public/icons/icon-info-blue.svg'), { loading: () => null }),
  [IconType['icon-info-outlined']]: dynamic(() => import('public/icons/icon-info-outlined.svg'), { loading: () => null }),
  [IconType['icon-info-red']]: dynamic(() => import('public/icons/icon-info-red.svg'), { loading: () => null }),
  [IconType['icon-info']]: dynamic(() => import('public/icons/icon-info.svg'), { loading: () => null }),
  [IconType['icon-info-solid']]: dynamic(() => import('public/icons/icon-info-solid.svg'), { loading: () => null }),
  [IconType['icon-warning-sign']]: dynamic(() => import('public/icons/warning-sign.svg'), { loading: () => null }),
  [IconType['icon-phoebe-avatar']]: dynamic(() => import('public/icons/icon-phoebe-avatar.svg'), { loading: () => null }),
  [IconType['icon-back-arrow']]: dynamic(() => import('public/icons/back-arrow.svg'), { loading: () => null }),
  [IconType['icon-public-logo']]: dynamic(() => import('public/icons/public-logo.svg'), { loading: () => null }),
  [IconType['icon-spy-glass']]: dynamic(() => import('public/icons/icon-spy-glass.svg'), { loading: () => null }),
  [IconType['icon-failed']]: dynamic(() => import('public/icons/icon-failed.svg'), { loading: () => null }),
  [IconType['icon-plus-mc']]: dynamic(() => import('public/icons/icon-plus-mc.svg'), { loading: () => null }),
  [IconType['icon-minus-mc']]: dynamic(() => import('public/icons/icon-minus-mc.svg'), { loading: () => null }),
  [IconType['icon-triangle']]: dynamic(() => import('public/icons/icon-triangle.svg'), { loading: () => null }),
  [IconType['icon-alert-mc']]: dynamic(() => import('public/icons/icon-alert-mc.svg'), { loading: () => null }),
  [IconType['icon-letter-closed']]: dynamic(() => import('public/icons/icon-letter-closed.svg'), { loading: () => null }),
  [IconType['icon-letter-open']]: dynamic(() => import('public/icons/icon-letter-open.svg'), { loading: () => null }),
  [IconType['icon-linkedin']]: dynamic(() => import('public/icons/icon-linkedin.svg'), { loading: () => null }),
  [IconType['icon-download']]: dynamic(() => import('public/icons/icon-download.svg'), { loading: () => null }),
  [IconType['icon-close']]: dynamic(() => import('public/icons/icon-close.svg'), { loading: () => null }),
  [IconType['icon-refresh']]: dynamic(() => import('public/icons/icon-refresh.svg'), { loading: () => null }),
  [IconType['icon-emotion-agree-strongly']]: dynamic(() => import('public/icons/icon-emotion-agree-strongly.svg'), { loading: () => null }),
  [IconType['icon-emotion-agree']]: dynamic(() => import('public/icons/icon-emotion-agree.svg'), { loading: () => null }),
  [IconType['icon-emotion-neutral']]: dynamic(() => import('public/icons/icon-emotion-neutral.svg'), { loading: () => null }),
  [IconType['icon-emotion-disagree']]: dynamic(() => import('public/icons/icon-emotion-disagree.svg'), { loading: () => null }),
  [IconType['icon-emotion-disagree-strongly']]: dynamic(() => import('public/icons/icon-emotion-disagree-strongly.svg'), { loading: () => null }),
  [IconType['icon-share']]: dynamic(() => import('public/icons/icon-share.svg'), { loading: () => null }),
  [IconType['icon-small-line-separator']]: dynamic(() => import('public/icons/icon-small-line-separator.svg'), { loading: () => null }),
  [IconType['icon-congratulations-mc']]: dynamic(() => import('public/icons/icon-congratulations-mc.svg'), { loading: () => null }),
  [IconType['icon-circle']]: dynamic(() => import('public/icons/icon-circle.svg'), { loading: () => null }),
  [IconType['icon-circle-check']]: dynamic(() => import('public/icons/icon-circle-check.svg'), { loading: () => null }),
  [IconType['icon-grad-cap-mc']]: dynamic(() => import('public/icons/icon-grad-cap-mc.svg'), { loading: () => null }),
  [IconType['icon-phoenix-link-careers']]: dynamic(() => import('public/icons/icon-phoenix-link-careers.svg'), { loading: () => null }),
  [IconType['icon-money']]: dynamic(() => import('public/icons/icon-money.svg'), { loading: () => null }),
  [IconType['icon-apple-book']]: dynamic(() => import('public/icons/icon-apple-book.svg'), { loading: () => null }),
  [IconType['icon-virtual-student-union']]: dynamic(() => import('public/icons/icon-virtual-student-union.svg'), { loading: () => null }),
  [IconType['icon-bell-badge-mc']]: dynamic(() => import('public/icons/icon-bell-badge-mc.svg'), { loading: () => null }),
  [IconType['icon-document-edit-mc']]: dynamic(() => import('public/icons/icon-document-edit-mc.svg'), { loading: () => null }),
  [IconType['icon-warning-mc']]: dynamic(() => import('public/icons/icon-warning-mc.svg'), { loading: () => null }),
  [IconType['icon-oh-dear-mc']]: dynamic(() => import('public/icons/icon-oh-dear-mc.svg'), { loading: () => null }),
  [IconType['icon-class-mc']]: dynamic(() => import('public/icons/icon-class-mc.svg'), { loading: () => null }),
  [IconType['icon-contact']]: dynamic(() => import('public/icons/icon-contact.svg'), { loading: () => null }),
  [IconType['icon-calendar-mc']]: dynamic(() => import('public/icons/icon-calendar-mc.svg'), { loading: () => null }),
  [IconType['icon-vsu-advice']]: dynamic(() => import('public/icons/vsu-advice.svg'), { loading: () => null }),
  [IconType['icon-vsu-balance']]: dynamic(() => import('public/icons/vsu-balance.svg'), { loading: () => null }),
  [IconType['icon-vsu-connect']]: dynamic(() => import('public/icons/vsu-connect.svg'), { loading: () => null }),
  [IconType['icon-vsu-findadoc']]: dynamic(() => import('public/icons/vsu-findadoc.svg'), { loading: () => null }),
  [IconType['icon-vsu-problem']]: dynamic(() => import('public/icons/vsu-problem.svg'), { loading: () => null }),
  [IconType['icon-vsu-study']]: dynamic(() => import('public/icons/vsu-study.svg'), { loading: () => null }),
  [IconType['icon-pause-mc']]: dynamic(() => import('public/icons/icon-pause-mc.svg'), { loading: () => null }),
  [IconType['icon-missed-mc']]: dynamic(() => import('public/icons/icon-missed-mc.svg'), { loading: () => null }),
  [IconType['icon-caret']]: dynamic(() => import('public/icons/caret.svg'), { loading: () => null }),
  [IconType['icon-thumbs-up-mc']]: dynamic(() => import('public/icons/icon-thumbs-up-mc.svg'), { loading: () => null }),
  [IconType['icon-star']]: dynamic(() => import('public/icons/icon-star.svg'), { loading: () => null }),
  [IconType['icon-star-1']]: dynamic(() => import('public/icons/icon-star-1.svg'), { loading: () => null }),
  [IconType['icon-voucher-invalid']]: dynamic(() => import('public/icons/icon-voucher-invalid.svg'), { loading: () => null }),
  [IconType['icon-voucher-duplicate']]: dynamic(() => import('public/icons/icon-voucher-duplicate.svg'), { loading: () => null }),
  [IconType['icon-misc-newtab']]: dynamic(() => import('public/icons/misc-newtab.svg'), { loading: () => null }),
  [IconType['icon-bulleted-lines']]: dynamic(() => import('public/icons/icon-bulleted-lines.svg'), { loading: () => null }),
  [IconType['icon-clock']]: dynamic(() => import('public/icons/icon-clock.svg'), { loading: () => null }),
  [IconType['icon-pdf-small']]: dynamic(() => import('public/icons/icon-pdf-small.svg'), { loading: () => null }),
  [IconType['icon-check-circle-mc']]: dynamic(() => import('public/icons/icon-check-circle-mc.svg'), { loading: () => null }),
  [IconType['icon-alert-sharp']]: dynamic(() => import('public/icons/icon-alert-sharp.svg'), { loading: () => null }),
  [IconType['icon-green-circle-check']]: dynamic(() => import('public/icons/icon-green-circle-check.svg'), { loading: () => null }),
  [IconType['icon-orange-alert-mc']]: dynamic(() => import('public/icons/icon-orange-alert-mc.svg'), { loading: () => null }),
  [IconType['icon-diploma-ribbon']]: dynamic(() => import('public/icons/icon-diploma-ribbon.svg'), { loading: () => null }),
  [IconType['icon-documents-stack']]: dynamic(() => import('public/icons/icon-documents-stack.svg'), { loading: () => null }),
  [IconType['icon-bio-science']]: dynamic(() => import('public/icons/icon-bio-science.svg'), { loading: () => null }),
  [IconType['icon-social-science']]: dynamic(() => import('public/icons/icon-social-science.svg'), { loading: () => null }),
  [IconType['icon-elective']]: dynamic(() => import('public/icons/icon-elective.svg'), { loading: () => null }),
  [IconType['icon-science-tech']]: dynamic(() => import('public/icons/icon-science-tech.svg'), { loading: () => null }),
  [IconType['icon-book-humanities']]: dynamic(() => import('public/icons/icon-book-humanities.svg'), { loading: () => null }),
  [IconType['icon-apple-clipboard']]: dynamic(() => import('public/icons/icon-apple-clipboard.svg'), { loading: () => null }),
  [IconType['icon-drawing-board']]: dynamic(() => import('public/icons/icon-drawing-board.svg'), { loading: () => null }),
  [IconType['icon-black-red-check']]: dynamic(() => import('public/icons/icon-black-red-check.svg'), { loading: () => null }),
  [IconType['icon-add-red']]: dynamic(() => import('public/icons/icon-add-red.svg'), { loading: () => null }),
  [IconType['icon-info-red-large']]: dynamic(() => import('public/icons/icon-info-red-large.svg'), { loading: () => null }),
  [IconType['icon-celebration']]: dynamic(() => import('public/icons/icon-celebration.svg'), { loading: () => null }),
  [IconType['icon-padlock']]: dynamic(() => import('public/icons/icon-padlock.svg'), { loading: () => null }),
  [IconType['icon-career-navigator']]: dynamic(() => import('public/icons/icon-career-navigator.svg'), { loading: () => null }),
  [IconType.empty]: EmptyIcon, // Do not make EmptyIcon dynamic
};

export default icons;
